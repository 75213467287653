<template>

  <v-container class="h-100">

    <div class="py-3">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Что</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
    </div>

    <div class="remaining-space">

      <div class="columns py-3">
        <div class="column is-6 is-12-mobile pa-0">
          <div class="columns is-mobile">
            <div class="column is-6 is-12-mobile pa-1">
              <div
                class="grid-item h-100 gradient-block-1"
                @click="selectBlock(0)"
                :style="{ outline: selectedBlock === 0 ? '2px solid #b5b5b5' : 'none' }"
              >
                <div class="block-content">
                  <p class="title is-5">{{ blocks[0].text }}</p>
                  <img :src="blocks[0].image" :alt="blocks[0].text">
                </div>
              </div>
            </div>

            <div class="column is-6 is-12-mobile pa-1">
              <div
                class="grid-item h-100 gradient-block-2"
                @click="selectBlock(1)"
                :style="{ outline: selectedBlock === 1 ? '2px solid #b5b5b5' : 'none' }"
              >
                <div class="block-content">
                  <p class="title is-5">{{ blocks[1].text }}</p>
                  <img :src="blocks[1].image" :alt="blocks[1].text">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-6 is-12-mobile pa-0">
          <div class="columns is-mobile">
            <div class="column is-6 is-12-mobile pa-1">
              <div
                class="grid-item h-100 gradient-block-1"
                @click="selectBlock(2)"
                :style="{ outline: selectedBlock === 2 ? '2px solid #b5b5b5' : 'none' }"
              >
                <div class="block-content">
                  <p class="title is-5">{{ blocks[2].text }}</p>
                  <img :src="blocks[2].image" :alt="blocks[2].text">
                </div>
              </div>
            </div>

            <div class="column is-6 is-12-mobile pa-1">
              <div
                class="grid-item h-100 gradient-block-1"
                @click="selectBlock(3)"
                :style="{ outline: selectedBlock === 3 ? '2px solid #b5b5b5' : 'none' }"
              >
                <div class="block-content">
                  <p class="title is-5">{{ blocks[3].text }}</p>
                  <img :src="blocks[3].image" :alt="blocks[3].text">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="pa-1 has-text-centered">
      <div class="columns is-mobile">
        <div class="column is-half pa-0 pr-1">
          <button @click="callNow" class="button is-light w-100">
            <span>Позвонить</span>
            <span class="icon">
              <i class="mdi mdi-phone"></i>
            </span>
          </button>
        </div>
        <div class="column is-half pa-0 pl-1">
          <button @click="showSelectedBlock" class="button is-light w-100">
            <span>К заказу</span>
            <span class="icon">
              <i class="mdi mdi-chevron-right"></i>
            </span>
          </button>
        </div>
      </div>
      <img
        class="mt-4"
        @click="goToActivityFeed"
        src="../../../../assets/expand_more.svg"
        alt="На главную">
    </div>


  </v-container>

</template>

<script>
export default {
  name: 'MainPage',
  data() {
    return {
      blocks: [
        { text: 'Мотоцикл', image: '/assets/v2/scooter.svg' },
        { text: 'Автомобиль', image: '/assets/v2/car.svg' },
        { text: 'Грузовик', image: '/assets/v2/big-car.svg' },
        { text: 'Спецтехника', image: '/assets/v2/special_equipment.svg' }
      ],
      selectedBlock: 1
    };
  },
  created() {
    if (this.showOnBoarding == true) {
      this.$router.push('/onboarding');
    } else {
      if (!this.isTransporter) {
        this.$store.dispatch('GET_ACTIVE_ORDER').finally(() => {
          if (this.isActiveOrder) {
            this.$router.push('/activity-feed');
          }
        });
      } else {
        // тут перевозчика быть не должно
        this.$router.push('/activity-feed');
      }

      this.$store.commit('SET_BLOCK_TYPE', null);
    }
  },
  computed: {
    showOnBoarding() {
      return this.$store.getters.SHOW_ONBOARDING;
    },
    isTransporter() {
      return this.$store.getters.ACCOUNT_TYPE == 1;
    },
    isActiveOrder() {
      return this.$store.getters.IS_ACTIVE_ORDER;
    },
  },
  methods: {
    callNow() {

      let num = process.env.VUE_APP_PHONE_CORPORATIVE;

      // Попробовать открыть приложение для звонков
      const link = document.createElement('a');
      link.href = `tel:${num}`;
      link.click();

      // Проверка на поддержку схемы tel в десктопных браузерах (например, Chrome)
      if (!navigator.userAgent.includes("Mobile")) {
        // Если десктопный браузер, копируем номер в буфер обмена
        this.copyToClipboard(num);
        this.$buefy.toast.open({
          message: 'Номер скопирован',
          type: 'is-success'
        })
      }
    },
    copyToClipboard(text) {
      // Создаем временный элемент input для копирования текста
      let input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
    selectBlock(index) {
      this.selectedBlock = index;
    },
    showSelectedBlock() {
      if (this.selectedBlock !== null) {
        this.$store.commit('SET_BLOCK_TYPE', this.selectedBlock);
        this.$router.push('/order/create/1');
      } else {
        this.$buefy.toast.open({
           message: 'Выберите блок, прежде чем нажать на кнопку',
           type: 'is-warning'
        });
      }
    },
    goToActivityFeed() {
      this.$router.push('/activity-feed');
    },
  },
};
</script>

<style scoped>

.block-content {
  text-align: center;
}

.block-content img {
  max-width: 100%;
  height: auto;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-gap: 10px;

    width: 100%;
    height: 100%;
}

.grid-item {
    padding: 20px;
    font-size: 30px;
    text-align: center;

    border-radius: 20px;
    cursor: pointer;
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

.container.all-place {
  height: calc(100vh - var(--header-height) - var(--padding-auto)*2);
}

.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}

.title.is-5 {
  font-size: 1.25rem !important;
}

@media screen and (max-width: 400px) {
  .title.is-5 {
    font-size: 1.1rem !important;
  }
}

@media screen and (max-width: 370px) {
  .title.is-5 {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 354px) {
  .title.is-5 {
    font-size: 0.9rem !important;
  }
}

</style>
